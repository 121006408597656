@import 'scss/custom';

.jodit-ui-button_variant_primary {
    background-color: #272d55 !important;
    color: #fff !important;
}
  .jodit-ui-spacer {
     -webkit-box-flex: 1;
     -ms-flex: 1;
     flex: 0;
 }

 .jodit-toolbar-button button {
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     height: 34px;
     min-width: 34px;
     padding: 0;
     /* color: #272d55; */
     /* background-color: #272d55; */
 }