$primaryColor: #0bb7a7;
$lightprimaryColor: #97ece4;

$dangerColor: #f44336;
$lightdangerColor: #f3dcdc;

$primary: $primaryColor !default;

$secondaryColor: #b8c1cb;
$secondary: $secondaryColor !default;

$tertiaryColor: #364155 !default;

$bodybackgroundColor: #f5fcfb;

$activebuttonColor: #97ece4a6;
$buttontextColor: #1f1d1d;
$buttonborderColor: #79e4da;

$primarygrayColor: #666673;
$lightColor: #ffffff;
$darkColor: #000000;
$grayColor: #f6f1f1;
$somwhatgrayColor: #7f7f7f;
$documentbgColor: #f5fcfb;
$shadowColor: #00000026;
/*$secondaryColor:     $grayColor-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$lightColor:         $grayColor-100 !default;
$dark:          $grayColor-800 !default;*/
$enable-shadows: true;
$layout-content-padding-left: 20px;
$layout-content-padding-right: 10px;
$layout-content-padding-top: 20px;
$layout-content-padding-bottom: 15px;

// List of all font sizes
$font-size-t: 1rem; //16px
$font-size-s: 1.125rem; //18px
$font-size-m: 1.25rem; //20px
$font-size-l: 1.375rem; //22px
$font-size-xl: 1.5rem; //24px
$font-size-xxl: 1.5625rem; //25px
$font-size-xxxl: 1.75rem; //28px

// $text-small: 13px;
// $text-regular: 15px;
// $text-medium: 16px;
// $text-large: 20px;
// $text-x-large: 22px;
// $text-xx-large: 24px;

//for toast messages
$toast-green: #0bb7a7;
/*$toast-red: $danger;
$toast-blue: #0086d6;
$toast-yellow: #ffbb00;*/
