@import '../../../scss/variables';

#custom-modal {
	border: 2px solid $primaryColor;
	position: relative;
	.cross {
		position: absolute;
		top: 2%;
		right: 1%;
	}
	.flat-import {
		.transition-modal-title {
			margin: 1rem;
			font-size: small;
			font-weight: 600;

			.import-icon {
				width: 2rem;
				height: 2rem;
				color: $primaryColor;
			}
		}
		.modal-container {
			width: 95%;
			margin: auto;
		}
	}
}
