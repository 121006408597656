// If you want to override variables do it here
@import 'fonts';


//@import '~animate.css';
// @import '~react-toastify/dist/ReactToastify.min.css';
// @import '~swiper/components/navigation/navigation.scss';
// @import '~swiper/components/pagination/pagination.scss';
// @import '~swiper/components/scrollbar/scrollbar.scss';

html {
	font-size: 100%;
	overflow-y: hidden;
	scroll-behavior: smooth !important;

	// for material-ui table resizinf
	.resizer {
		display: inline-block;
		background: #aecdfc;
		width: 1px;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		transform: translateX(50%);
		z-index: 1;
		touch-action: none;

		&.isResizing {
			background: #9dc2fa;
		}
	}

	/* changing button colors and marker for material ui carousel ⬇*/
	// button[aria-label='Previous'] {
	// 	color: $buttontextColor !important;
	// 	background-color: $lightprimaryColor !important;
	// 	border: 2px solid $primaryColor;
	// 	box-shadow: 3px 3px 3px $shadowColor;
	// 	// .MuiSvgIcon-root {
	// 	// 	width: 2rem !important;
	// 	// 	height: 2rem !important;
	// 	// }
	// }

	// button[aria-label='Next'] {
	// 	color: $buttontextColor !important;
	// 	background-color: $lightprimaryColor !important;
	// 	border: 2px solid $primaryColor;
	// 	box-shadow: 3px 3px 3px $shadowColor;
	// 	// .MuiSvgIcon-root {
	// 	// 	width: 2rem !important;
	// 	// 	height: 2rem !important;
	// 	// }
	// }

	
	// circle {
	// 	color: $primaryColor;
	// }
	/* changing button colors and marker for material ui carousel ⬆*/

	/* removing focus on active item like button/link/tab*/
	*:focus {
		outline: none;
	}

	*::-webkit-scrollbar {
		width: 5px;
		height: 0.3rem;
	}

	*:hover::-webkit-scrollbar {
		width: 5px;
		height: 8px;
	}

	*::-webkit-scrollbar-track {
		// box-shadow: inset 0 0 5px $primaryColor;
	}

	*::-webkit-scrollbar-thumb {
		background: #aecdfc;
		border-radius: 10px;
		opacity: 0.5;
	}

	*::-webkit-scrollbar-track {
		// box-shadow: inset 0 0 5px $primaryColor;
		border-radius: 10px;
	}

	*::-webkit-scrollbar-corner {
		background-color: none;
	}
	
	/* FOR SWIPER's SCROLLBAR */
	body {
		margin: 0;
		font-family: 'Open Sans', sans-serif !important;
		background-color: #f3f5ff !important;
		iframe {
			position: relative !important;
		}
		
		

	
	
		
		

		

		/*🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽 CSS FOR INPUT FIELD 🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽*/

		/* removing up down arrows from input fields with type=number*/
		input[type='number']::-webkit-inner-spin-button,
		input[type='number']::-webkit-outer-spin-button {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			margin: 0;
		}

	

		

		

		/*🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽 CLASS TO HIDE SCROLLBARS 🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽*/
		/* Hide scrollbar for Chrome, Safari and Opera */
		.hide-scrollbars::-webkit-scrollbar {
			display: none;
		}

		/* Hide scrollbar for IE, Edge and Firefox */
		.hide-scrollbars {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}

		/*🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽 CSS FOR CUSTOM SCROLLBAR 🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽*/

		/* custom scrollbar */
		.custom-scrollbars {
			max-height: 23rem;
			overflow-x: hidden;
		}

		/*🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽 CSS FOR CAROUSEL CONTROLS 🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽*/

		

		/*🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽 OVERRIDING MATERIAL UI CSS 🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽🔽*/

		
		
		.fullscreen {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 9999;
			height: 100%;
		}
	}
}
*, :after, :before {
    box-sizing: border-box;
}
input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }

// blinker for performance testing metric icon 
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}